.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(5, 14, 42);
    padding: 0 20px; /* Add padding for mobile devices */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 400px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #3c3c3c;
    color: #fff;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: rgb(166, 246, 116);
    color: rgb(5, 14, 42);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #a6f674;
  }
  
  .error-message {
    color: #ff6666; /* Less intense shade of red */
    margin: 10px 0;
  }