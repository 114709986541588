.MuiPaper-root {
  background-color: transparent !important;
  border-bottom: solid 1px white;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiAccordionSummary-root {
  padding: 0 !important;
  min-height: 25px !important;
  margin-top: 20px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiIconButton-root {
  padding: 0 10px !important;
}
.MuiAccordionSummary-content {
  margin: 5px 0 !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
ul {
  list-style: none;
  padding: 0;
  margin: 5px 0;
}
