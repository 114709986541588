.rcw-conversation-container .rcw-header {
    background-color: #0E1D39;
}

.rcw-launcher {
    background-color: #0E1D39;
}

.chat-bottom {
    display: none;
}

.rcw-response .rcw-message-text>p {
    white-space: pre-wrap;
    color: #0E1D39;
    font-size: 16px;
}

.rcw-timestamp {
    font-size: 10px !important;
}

.rcw-picker-icon {
    display: none;
}

.rcw-message-text p {
    color: #0E1D39;
    font-size: 16px;
}

.rcw-conversation-container .rcw-header span {
    font-size: 18px;
}

@media screen and (max-width: 800px) {
    .rcw-conversation-container .rcw-close-button {
        background-color: #0E1D39;
    }
    .rcw-conversation-container {
        height: 100vh;
    }
    .rcw-widget-container {
        height: 100%;
        max-height: 100vh;
    }
    .isIphone .rcw-widget-container {
        height: auto;
        max-height: initial;
    }
}
